@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}


body::-webkit-scrollbar {
	width: 3px;
}

div::-webkit-scrollbar {
	background-color: transparent;
	width: 10px;
}

div::-webkit-scrollbar-thumb {
	width: 10px;
	background-color: rgb(179, 174, 174);
	border-radius: 4px;
}

ul::-webkit-scrollbar {
	background-color: transparent;
	width: 10px;
	border-radius: 40px !important;
}

ul::-webkit-scrollbar-thumb {
	border-radius: 40px !important;
	width: 10px;
	background-color: rgb(179, 174, 174);
	border-radius: 4px;
}

::-webkit-scrollbar-thumb:horizontal {
	/* display: none; */
	background-color: rgb(222, 218, 218);
	width: 2px;
	height: 10px;
}


.react-datepicker-popper {
    z-index: 9999 !important;
}


#filter-startdate > .react-datepicker-wrapper, #filter-enddate > .react-datepicker-wrapper{
	width: 100% !important;
}  

#date-picker-custom-id > .react-datepicker-wrapper{
	width: 100% !important;
}

.white-image {
	filter: invert(100%) brightness(0) saturate(1000%);
  }